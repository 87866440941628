import React from "react"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { ConfirmProvider } from "material-ui-confirm"
import { ErrorBoundary } from "react-error-boundary"
import { RouterProvider } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import ErrorFallback from "components/errors/ErrorFallback"
import ToastButtons from "components/toasts/ToastButtons"
import BackendProvider from "providers/BackendProvider"
import router from "router"
import theme from "theme"

const App: React.FC = () => (
  <div>
    <title>Distillery</title>
    <meta
      name="description"
      content="Distillery - Enabling product and marketing teams to make better decisions with AI-driven personalised data insights"
    />

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <BackendProvider>
            <ConfirmProvider
              defaultOptions={{
                confirmationButtonProps: { autoFocus: true },
              }}
            >
              <ToastContainer
                autoClose={8000}
                position="bottom-left"
                closeButton={ToastButtons}
              />
              <RouterProvider router={router} />
            </ConfirmProvider>
          </BackendProvider>
        </ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  </div>
)

export default App
