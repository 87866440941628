import {
  ApolloClient,
  from,
  InMemoryCache,
  split,
  TypePolicies,
} from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { getMainDefinition } from "@apollo/client/utilities"
import DebounceLink from "apollo-link-debounce"
import createUploadLink from "apollo-upload-client/createUploadLink.mjs"
import { createClient } from "graphql-ws"

const DEFAULT_DEBOUNCE_TIMEOUT = 100

export const typePolicies: TypePolicies = {
  Run: {
    fields: {
      rows: {
        keyArgs: ["size", "columns"], // Use "size" and "columns" as cache keys
        read: (existing, { readField }) => {
          if (existing) return existing

          const loading = readField("loading")

          if (loading === false) return existing

          return {
            data: [],
          }
        },
      },
    },
  },
  Row: {
    fields: {
      data: {
        merge: true,
      },
    },
  },
  Query: {
    fields: {
      run: {
        read: (_, { args, toReference }) =>
          args?.id
            ? toReference({ __typename: "Run", id: args.id })
            : undefined,
      },
    },
  },
}

export const cache = new InMemoryCache({
  typePolicies,
})

export const baseURL = import.meta.env.VITE_BASE_URL ?? "http://localhost:4000"

export const errorLink = (logoutUser: () => void) =>
  onError(({ graphQLErrors }) => {
    if (graphQLErrors?.[0].message === "User is not authenticated") {
      logoutUser()
    }
  })

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${baseURL.replace("http", "ws")}/subscriptions`,
    shouldRetry: () => true,
  }),
)

const uploadLink = createUploadLink({
  uri: `${baseURL}/graphql/`,
  credentials: "include",
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    )
  },
  wsLink,
  uploadLink,
)

const make_client = (logoutUser: () => void) => {
  return new ApolloClient({
    cache,
    link: from([
      new DebounceLink(DEFAULT_DEBOUNCE_TIMEOUT),
      errorLink(logoutUser),
      splitLink,
    ]),
    connectToDevTools: true,
  })
}

export default make_client
