import React from "react"
import { Close } from "@mui/icons-material"
import { Box, IconButton } from "@mui/material"

type ToastButtonsProps = {
  closeToast: () => void
  actions?: React.ReactNode
}

const ToastButtons = ({ closeToast, actions }: ToastButtonsProps) => (
  <Box sx={{ ml: "auto", display: "flex", gap: 2 }}>
    {actions}
    <IconButton onClick={closeToast}>
      <Close />
    </IconButton>
  </Box>
)

export default ToastButtons
